import { FC } from "react";
import { ClientProps } from "../../../hooks/useClientData";
import BaseUserAvatar from "../../../components/reusable/BaseUserAvatar";
import { leaderboardUsers } from "../../../types/leaderboardUsersResponse";
import { formattedTime } from "../../../helperFunctions/formatMsToTime";
import { brand } from "@/brandVariables/getBandVariables";

export const Racing_leaderboard: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const users = clientData.leaderboard.data.slice(0, 7) as leaderboardUsers[];
  const isElgiganten = brand.client === "ELGIGANTEN" ? "elgiganten" : "";

  return (
    <>
      <ul>
        <li className="leaderboard__legend">
          <p>Rank</p>
          <p>Player</p>
          <p>Car</p>
          <p>Time</p>
        </li>
        {users?.length !== 0 &&
          users &&
          users.map((user: leaderboardUsers, index) => {
            return (
              <li
                id={`player-${user.userChallengeData.rank}`}
                className="leaderboard__user"
              >
                <div className="user">
                  <span className="user__position background">
                    <div
                      className={`background__angle-top-right ${isElgiganten}`}
                    />

                    <p>{user.userChallengeData.rank}</p>
                  </span>

                  <div className="user__info">
                    {user.profile.profileLogoUrl &&
                    !user.profile.profileLogoUrl.includes("/static") ? (
                      <BaseUserAvatar
                        userName={user.profile.userName}
                        logoUrl={user.profile.profileLogoUrl}
                      />
                    ) : (
                      <h5>{user.profile.userName.slice(0, 1)}</h5>
                    )}
                    <div className="user__names">
                      <p className="user__name">
                        {user.profile.userName ||
                          user.userChallengeData.inGameUserName}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p>
                      {user.userChallengeData?.gameSpecificData?.teamName ||
                        "-"}
                    </p>
                  </div>
                  <span className="user__score">
                    <p>
                      {" "}
                      {formattedTime(user.userChallengeData.points) || "-"}{" "}
                    </p>
                  </span>
                </div>
              </li>
            );
          })}

        {users?.length !== 0 &&
          users &&
          users.length < 10 &&
          Array.from(Array(7 - users.length + 1), () => {
            return <li className="leaderboard__user placeholder" />;
          })}
      </ul>
    </>
  );
};
