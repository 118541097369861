import brandLogo from "../../assets/elgiganten_logo.svg";
export const ELGIGANTEN_brandVariables = {
  client: "ELGIGANTEN", // For picking wich leaderboard to show
  layoutKeyValue: "ELGIGANTEN",
  brand: "elgiganten", // For scss
  gameStyle: "racing", // For picking wich leaderboard to show
  eventCode: "elgiganten_blast_fall_2024", // Needed for fetching correct challenge
  challengeStatus: "ongoing",
  challengeGameType: "f12024",
  brandLogo: brandLogo,
  leaderboardLength: 7,
  prize: {
    nameOf: "Elgiganten Merch", // will be marked in color
    typeOfItem: "wireless gaming mouse", // will be added after nameOf
  },
};
