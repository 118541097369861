import React, { FC, useEffect, useState } from "react";
import firebase from "firebase/app";

import ChallengeCard from "../components/JBL/components/challengeCard";
import { ClientProps } from "../hooks/useClientData";
import { brand } from "../brandVariables/getBandVariables";
import { WinnerCard } from "../components/RACING/components/WinnerCard";

const winningDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
// END SCREEN PAGE, FOR WHEN THERE ARE NO MORE EVENTS PLANNED FOR THIS CLIENT/EVENT
export const EndScreen: FC<{
  clientData: ClientProps;
}> = ({ clientData }): JSX.Element => {
  const winners = clientData.winners.data;
  const isElgigantenAlt = brand.client === "ELGIGANTEN" ? "isElgiganten" : "";
  const isElgiganten = brand.client === "ELGIGANTEN" ? "text-gradient" : "";
  console.log(clientData);
  const Logo = brand.brandLogo;
  return (
    <div className="endScreen">
      <div className="endScreen__heading">
        <h1>
          {winners ? (
            <>
              Thank you and congratulations to our{" "}
              <span className={isElgiganten}>winners</span>!
            </>
          ) : (
            <>Thank you to all participants</>
          )}
        </h1>
        <div className="brand-logo">
          <Logo width="220" />
        </div>
      </div>

      {winners && (
        <div className="winners">
          {winningDays.map((day) => {
            if (winners.filter((obj) => obj.winningDay === day).length !== 0) {
              return (
                <section>
                  <div className={`winners__header ${isElgigantenAlt}`}>
                    <h2>{day}</h2>
                  </div>
                  <div className="winners__day">
                    {winners.map((winner) => {
                      if (winner.winningDay === day) {
                        return (
                          <WinnerCard
                            winnerToUser={winner}
                            clientData={clientData}
                            hasBanner={false}
                          />
                        );
                      }
                    })}
                  </div>
                </section>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};
