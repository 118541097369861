import { useState, useEffect } from "react";
import firebase from "firebase/app";
import { brand } from "../brandVariables/getBandVariables";
import { Challenge } from "../types/challengeDataResponse";

export const useCurrentEventChallenges = () => {
  const [loadingNextChallenge, setLoadingNextChallenge] = useState(true);
  const [nextChallenges, setNextChallenges] = useState<Challenge[] | null>(
    null
  );
  const [loadingOngoingChallenge, setLoadingOngoingChallenge] = useState(true);
  const [ongoingChallenge, setOngoingChallenge] = useState<any>(null);
  const upComingChallengesQuery = firebase
    .firestore()
    .collection("challenges")
    .where("client", "==", brand.client)
    .where("eventCode", "==", brand.eventCode)
    .where("status", "==", "upcoming");

  const ongoingChallengesQuery = firebase
    .firestore()
    .collection("challenges")
    .where("client", "==", brand.client)
    .where("eventCode", "==", brand.eventCode)
    .where("status", "==", "ongoing")
    .limit(1);

  const fetchUpcomingData = async () => {
    try {
      setLoadingNextChallenge(true);
      console.log("Fetching next challenges!");

      upComingChallengesQuery.onSnapshot((snapshotForUpcoming) => {
        if (snapshotForUpcoming.docs.length > 0) {
          const upcomingChallengesData = snapshotForUpcoming.docs.map((doc) =>
            doc.data()
          ) as Challenge[];

          upcomingChallengesData.sort((a, b) => {
            return a.startDate.seconds * 1000 - b.startDate.seconds * 1000;
          });

          setNextChallenges(upcomingChallengesData);
        }
        setLoadingNextChallenge(false);
      });
    } catch (error) {
      setLoadingNextChallenge(false);
      console.error("Error:", error);
    } finally {
      setLoadingNextChallenge(false);
    }
  };

  const fetchOngoingData = async () => {
    try {
      setLoadingOngoingChallenge(true);
      console.log("Fetching ongoing challenge!");
      ongoingChallengesQuery.onSnapshot((snapshotForOngoing) => {
        const challenge = snapshotForOngoing.docs.map((doc) => doc.data())[0];
        if (challenge) {
          setOngoingChallenge(challenge);
        }
        setLoadingOngoingChallenge(false);
      });
    } catch (error) {
      setLoadingOngoingChallenge(false);
      console.error("Error:", error);
    } finally {
      setLoadingOngoingChallenge(false);
    }
  };

  // Check if its init run/data connection has already been initialized
  useEffect(() => {
    if (nextChallenges) {
      return;
    }
    fetchUpcomingData();
  }, []);

  useEffect(() => {
    if (ongoingChallenge) {
      return;
    }
    fetchOngoingData();
  });

  return {
    fetchUpcomingData,
    loadingNextChallenge,
    nextChallenges,
    fetchOngoingData,
    loadingOngoingChallenge,
    ongoingChallenge,
  };
};
