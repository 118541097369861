import { FC } from "react";
import { ClientProps } from "../../../../hooks/useClientData";
import { MapsList } from "../../../../consts/MapsLIst";
import { useTimer } from "../../../../hooks/useCountDown";
import { TimerComp } from "../TimerComp";
import { brand } from "@/brandVariables/getBandVariables";
import { ReactComponent as ElgigantenMap } from "../../assets/austria_elgiganten.svg";
import { ReactComponent as ErrorWarning } from "../../assets/warning_triangle.svg";

export const LeaderboardIsEmpty: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const MapRender = MapsList["grand oak"];
  const isElgiganten = brand.client === "ELGIGANTEN" ? "text-gradient" : "";
  const isElgigantenAlt = brand.client === "ELGIGANTEN" ? "elgiganten" : "";

  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    clientData.challenge.data?.endDate || undefined
  );

  const mapToRender = () => {
    if (brand.client === "ELGIGANTEN") {
      return <ElgigantenMap />;
    } else {
      return <MapRender />;
    }
  };

  return (
    <div className="leaderboard__empty">
      <section className="empty__heading">
        <div className="winner__banner background">
          <div
            className={`background__angle-top-right ${isElgigantenAlt} `}
          ></div>
          <p>Main track details</p>
        </div>
        <div className="heading__timer">
          <TimerComp time={time} />
        </div>
      </section>
      <section className="leaderboard__track-info">
        <div className="track-info">
          <div className="track-info__track">
            <p>Track</p>
            <span className={`${isElgiganten}`}>
              {/* {clientData.challenge?.data?.challengeAdditionalData
                .mapNames?.[0] || ""} */}
              Austria - RED BULL RING
            </span>
          </div>

          <div className="track-info__meta">
            <div>
              <div>
                <p>turns</p>
                <span className={`${isElgiganten}`}>10</span>
              </div>
              <div>
                <p>Circuit lenght</p>
                <span className={`${isElgiganten}`}>4.318 Km</span>
              </div>
            </div>

            <div>
              <div>
                <p>Track difficulty</p>
                <span className={`${isElgiganten}`}>Very easy</span>
              </div>
              <div>
                <p>Lap record</p>
                <span className={`${isElgiganten}`}>1:05:619</span>
              </div>
            </div>
          </div>
        </div>
        <div className="track-info__map">{mapToRender()}</div>
      </section>
    </div>
  );
};
