import { FC, useEffect } from "react";
import { ClientProps } from "../../hooks/useClientData";
import { brand } from "../../brandVariables/getBandVariables";
import "./styles/index.scss";
import { WinnerCard } from "./components/WinnerCard";
import { useTimer } from "../../hooks/useCountDown";
import { setCurrentChallengeToOnGoing } from "@/helperFunctions/setCurrentChallengeToOnGoing";

export const RACINGWaitingScreen: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    clientData.nextChallenges?.data?.[0]?.startDate || undefined
  );
  const isElgiganten = brand.client === "ELGIGANTEN" ? "text-gradient" : "";
  const isElgigantenAlt = brand.client === "ELGIGANTEN" ? "isElgiganten" : "";

  useEffect(() => {
    let hasRan;

    if (
      !clientData.challenge.data &&
      !hasRan &&
      !clientData.nextChallenges.loading &&
      clientData.nextChallenges.data?.[0] &&
      isTimeOver
    ) {
      hasRan = true;

      setCurrentChallengeToOnGoing(clientData.nextChallenges.data?.[0].slug);
    }
  }, [time, isTimeOver]);

  return (
    <>
      <div className="waiting-screen">
        <section>
          <div className="waiting-screen__countdown">
            <div className="waiting-screen__heading">
              <div className="brand-logo">
                <img src={brand.brandLogo} alt="" />
              </div>
            </div>
            <h2>New challenge in</h2>
            <section>
              <div>
                <p className={`${isElgigantenAlt}`}>
                  {timeObj?.hours.replace(":", "")[0] || "-"}
                </p>
                <p className={`${isElgigantenAlt}`}>
                  {timeObj?.hours.replace(":", "")[1] || "-"}
                </p>
              </div>
              <span />
              <div>
                <p className={`${isElgigantenAlt}`}>
                  {timeObj?.minutes.replace(":", "")[0] || "-"}
                </p>{" "}
                <p className={`${isElgigantenAlt}`}>
                  {timeObj?.minutes.replace(":", "")[1] || "-"}
                </p>
              </div>
              <span />
              <div>
                <p className={`${isElgigantenAlt}`}>
                  {timeObj?.seconds.replace(":", "")[0] || "-"}
                </p>{" "}
                <p className={`${isElgigantenAlt}`}>
                  {timeObj?.seconds.replace(":", "")[1] || "-"}
                </p>
              </div>
            </section>
            {brand.prize && (
              <h3>
                win your share of{" "}
                <span className={`${isElgiganten}`}>{brand.prize.nameOf}</span>{" "}
              </h3>
            )}
          </div>
        </section>

        {clientData.winners.data !== null &&
        clientData.winners.data.length !== 0 ? (
          <section>
            <div className="waiting-screen__winners">
              <div className="winners">
                <div className="winners__heading background">
                  <div className="background__angle-top-right"></div>
                  <p>previous winners</p>
                </div>
              </div>
              <ul>
                {clientData.winners.data.slice(0, 3).map((winner) => {
                  return (
                    <WinnerCard
                      winnerToUser={winner}
                      clientData={clientData}
                      hasBanner={false}
                    />
                  );
                })}
              </ul>
            </div>
          </section>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
