import { FC, useEffect, useState } from "react";
import { ClientProps } from "../../hooks/useClientData";
import { brand } from "../../brandVariables/getBandVariables";
import { Racing_leaderboard } from "./components/Racing_leaderboard";
import "./styles/index.scss";
import { Racing_meta } from "./components/Racing_meta";
import { LeaderboardIsEmpty } from "./components/LeaderboardStates/LeaderboardIsEmpty";
import { LeaderboardHasOneScore } from "./components/LeaderboardStates/LeaderboardHasOneScore";
import { LeaderboardHasTwoScores } from "./components/LeaderboardStates/LeaderboardHastwoScores";
import { LeaderboardHasThreeScores } from "./components/LeaderboardStates/LeaderboardHasThreeScores";
import { useTimer } from "../../hooks/useCountDown";
import { EndScreen } from "../../pages/endScreen";
import { TimerComp } from "./components/TimerComp";
import { ReactComponent as ErrorWarning } from "./assets/warning_triangle.svg";

export const RACINGLeaderboard: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const hasActiveChallenge =
    clientData.leaderboard.data && !clientData.leaderboard.loading;

  const hasActiveChallengeData =
    clientData.hasActiveChallenge && clientData.leaderboard.data;

  const isElgiganten = brand.client === "ELGIGANTEN" ? "text-gradient" : "";

  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    clientData.challenge.data?.endDate || undefined
  );

  const leaderboardLength = clientData.leaderboard.length as number;
  // const leaderboardLength = 3 as number;
  const [endScreen, setEndScreen] = useState<boolean>(false);

  useEffect(() => {
    let hasRan;
    if (!hasRan && !clientData.nextChallenges.loading && isTimeOver) {
      hasRan = true;

      if (
        isTimeOver &&
        (!clientData.nextChallenges.data ||
          clientData.nextChallenges.data?.length === 0)
      ) {
        if (
          !clientData?.challenge?.data ||
          clientData.challenge.data.status === "finished"
        ) {
          setEndScreen(true);
          clearTimer();
        }
      }
    }
  }, [time, isTimeOver]);

  if (endScreen) {
    return <EndScreen clientData={clientData} />;
  }

  const LeaderboardToRender = () => {
    // return <LeaderboardHasThreeScores clientData={clientData} />;

    // return <LeaderboardIsEmpty clientData={clientData} />;

    if (!leaderboardLength || leaderboardLength === 0) {
      return <LeaderboardIsEmpty clientData={clientData} />;
    }

    if (brand.client === "LOGITECH" && leaderboardLength <= 3) {
      return <LeaderboardHasThreeScores clientData={clientData} />;
    }

    if (brand.client === "ELGIGANTEN" && leaderboardLength <= 3) {
      return <LeaderboardHasThreeScores clientData={clientData} />;
    }

    if (leaderboardLength === 1) {
      // Return leaderboard state for 1 user
      return <LeaderboardHasOneScore clientData={clientData} />;
    }

    if (leaderboardLength === 2) {
      // Return leaderboard state for 2 users
      return <LeaderboardHasTwoScores clientData={clientData} />;
    }

    if (leaderboardLength === 3) {
      // Return leaderboard state for 3 users
      return <LeaderboardHasThreeScores clientData={clientData} />;
    }

    // Return leaderboard state for all users
    return <Racing_leaderboard clientData={clientData} />;
  };

  return (
    <>
      {/* IF ACTIVE CHALLENGE*/}
      {hasActiveChallengeData && (
        <>
          <div className={`overview ${leaderboardLength === 0 ? "empty" : ""}`}>
            <div className="overview__heading">
              {false ? (
                <div className={leaderboardLength > 3 ? "__row" : ""}>
                  <h1>
                    Leaderboard <TimerComp time={time} justTimer />
                  </h1>
                  {hasActiveChallenge && (
                    <div className="brand-logo">
                      <img src={brand.brandLogo} alt="" />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div>
                    <h1>
                      {leaderboardLength === 0 ? "Set" : "Beat"} the best lap in
                      Time Trials
                    </h1>
                    {hasActiveChallenge && (
                      <div className="brand-logo">
                        <img src={brand.brandLogo} alt="" />
                      </div>
                    )}
                  </div>

                  <h2>
                    win your share of{" "}
                    <span className={isElgiganten}>{brand.prize.nameOf}</span>{" "}
                  </h2>
                </div>
              )}
            </div>

            <div
              className={`overview__content ${
                leaderboardLength === 0 ? "empty" : ""
              } ${leaderboardLength > 3 ? "full" : ""}`}
            >
              <section className="leaderboard">{LeaderboardToRender()}</section>

              {leaderboardLength !== 0 && (
                <section>
                  <Racing_meta clientData={clientData} />
                </section>
              )}
            </div>

            <div className="overview__disclaimer">
              <ErrorWarning />
              <small>
                only laps under 1:00 minute are considered for the leaderboards
              </small>
            </div>
          </div>
        </>
      )}
    </>
  );
};
